//COLORS
//--------------------------------------------------------
//Primary
$light-blue: #3c8dbc;
//Danger
$red: #dd4b39;
//Success
$green: #00a65a;
//Info
$aqua: #00c0ef;
//Warning
$yellow: #f39c12;
$blue: #0073b7;
$navy: #001F3F;
$teal: #39CCCC;
$olive: #3D9970;
$lime: #01FF70;
$orange: #FF851B;
$fuchsia: #F012BE;
$purple: #605ca8;
$maroon: #D81B60;
$black: #111;
$gray: #d2d6de;